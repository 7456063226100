import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConfirmDialogComponent } from '@ui/dialogs/confirm-dialog/confirm-dialog.component';
import { DialogCloseOpts } from '@ui/dialogs/confirm-dialog/models/dialog.config';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Auth2Service } from '../services/auth2.service';

export interface HasUnsavedData {
  hasUnsavedData(): boolean;
}

@Injectable()
export class AreUnsavedChangesGuard {
  constructor(
    private readonly dialog: MatDialog,
    private readonly authService: Auth2Service,
  ) {}

  canDeactivate(
    component: HasUnsavedData,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): boolean | Observable<boolean> {
    if (!this.authService.isValidSession()) {
      return true;
    }

    if (!component.hasUnsavedData()) {
      return true;
    }
    return this.dialog
      .open(ConfirmDialogComponent, {
        disableClose: true,
        data: {
          title: 'You have unsaved changes on this page',
          content:
            'If you leave this page, all unsaved changes will be lost. Are you sure you want to leave this page?',
          continueButtonLabel: 'Leave Page',
        },
      })
      .afterClosed()
      .pipe(
        take(1),
        map((selection: DialogCloseOpts) => selection === 'continue'),
      );
  }
}
